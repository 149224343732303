<template>
	<div class="msg_content">
		<div class="bloc_title">订单信息</div>
		<div class="order_list">
			<div class="list_item">
				<div class="title">车牌号</div>
				<div class="msg">{{orderInfo.carNumber}}</div>
			</div>
			<div class="list_item">
				<div class="title">厂商</div>
				<div class="msg">{{orderInfo.officeName}}</div>
			</div>
			<div class="list_item" style="width: 36%;">
				<div class="title">服务到期时间</div>
				<div class="msg">{{orderInfo.platformEndDate}}</div>
			</div>
			<div class="list_item">
				<div class="title">发货单号</div>
				<div class="msg">{{orderInfo.fahuodanhao}}</div>
			</div>
			<div class="list_item">
				<div class="title">发货数量</div>
				<div class="msg">{{orderInfo.fahuoshuliang}}</div>
			</div>
			<div class="list_item" style="width: 36%;">
				<div class="title">产品类型</div>
				<div class="msg">{{orderInfo.chanpinmingcheng}}</div>
			</div>
			<div class="list_item">
				<div class="title">包装方式</div>
				<div class="msg">{{orderInfo.baozhuangfangshi}}</div>
			</div>
			<div class="list_item">
				<div class="title">销售区域</div>
				<div class="msg">{{orderInfo.orderAreaName}}</div>
			</div>
			<div class="list_item" style="width: 36%;">
				<div class="title">出厂时间</div>
				<div class="msg">{{orderInfo.chuchangriqi}}</div>
			</div>
			<div class="list_item special">
				<div class="title">订单结束时间</div>
				<div class="msg">{{orderInfo.endTime}}</div>
			</div>
			<div class="list_item special">
				<div class="title">经销商名称</div>
				<div class="msg">{{orderInfo.agencyName}}</div>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	props: {
		orderInfo: {
			type: Object,
			default: ()=>{}
		}
	},
	data() {
		return {
			
		}
	}
}
</script>

<style lang="less" scoped>
.msg_content{
	padding: 1.5rem;
	background-color: #FFFFFF;
	.bloc_title{
		font-size: 1.6rem;
		color: #333333;
	}
	.order_list{
		background-color: #FFFFFF;
		display: flex;
		justify-content: flex-start;
		flex-wrap: wrap;
		margin-top: 1.5rem;
		.list_item{
			width: 32%;
			margin-bottom: 1rem;
			.title{
				font-size: 1.2rem;
				color: #999999;
				margin-bottom: .4rem;

			}
			.msg{
				font-size: 1.2rem;
				color: #333333;
			}
			.msg_status{
				font-size: 1.2rem;
			}
		}
		.special{
			width: 100%;
		}
	}
}
</style>
