<template>
    <div class="">
        <div class="list_content">
            <div class="list">
                <div class="list_title">围栏</div>
                <span :class="[!orderDetail.carId?'isUnusual':timeCssCementCheck(orderDetail.platformEndDate,orderDetail.chuchangriqi,orderDetail.railException)]">
                    {{!orderDetail.carId?'未安装设备':timeTextCementCheck(orderDetail.platformEndDate,orderDetail.chuchangriqi,orderDetail.railException)}}
                </span>
            </div>
            <div class="list">
                <div class="list_title">载重</div>
                <span :class="[!orderDetail.carId?'isUnusual':timeCssCementCheck(orderDetail.platformEndDate,orderDetail.chuchangriqi,orderDetail.loadException)]">
                    {{!orderDetail.carId?'未安装设备':timeTextCementCheck(orderDetail.platformEndDate,orderDetail.chuchangriqi,orderDetail.loadException)}}
                    </span>
            </div>
            <div class="list">
                <div class="list_title">照片</div>
                <span :class="[!orderDetail.carId?'isUnusual':timeCssCementCheck(orderDetail.platformEndDate,orderDetail.chuchangriqi,orderDetail.picException)]">
                    {{!orderDetail.carId?'未安装设备':timeTextCementCheck(orderDetail.platformEndDate,orderDetail.chuchangriqi,orderDetail.picException)}}
                    </span>
            </div>
            <div class="list">
                <div class="list_title">系统初审</div>
                <span :class="[!orderDetail.carId?'isUnusual':timeCssCementCheck(orderDetail.platformEndDate,orderDetail.chuchangriqi,orderDetail.isWaring)]">
                    {{!orderDetail.carId?'未安装设备':timeTextCementCheck(orderDetail.platformEndDate,orderDetail.chuchangriqi,orderDetail.isWaring)}}
                    </span>
            </div>
            <div class="list" v-show="checkProgress>1">
                <div class="list_title">市场部</div>
                <span :class="[orderDetail.marketCheckStatus == 1?'isNormal':orderDetail.marketCheckStatus?'':'isUnusual']">
                    {{orderDetail.marketCheckStatus | checkStatusFilter }}
                    </span>
            </div>
            <div class="list" v-show="checkProgress>2">
                <div class="list_title">销售处</div>
                <span :class="[orderDetail.salesCheckStatus == 1?'isNormal':orderDetail.salesCheckStatus?'':'isUnusual']">
                    {{orderDetail.salesCheckStatus  | checkStatusFilter }}
                    </span>
            </div>
            <div class="list" v-show="checkProgress>3">
                <div class="list_title">财务处</div>
                <span :class="[orderDetail.financialCheckStatus == 1?'isNormal':orderDetail.financialCheckStatus?'':'isUnusual']">
                    {{orderDetail.financialCheckStatus  | checkStatusFilter }}
                    </span>
            </div>
        </div>
        <div class="exam_list">
            <div class="exam_meta" v-show="checkProgress>1">
                <div class="exam_title">市场部意见</div>
                <div class="exam_enclosure" v-if="orderDetail.checkFiles" @click="goExamine">查看附件</div>
            </div>
            <div class="exam_content" v-show="checkProgress>1">
                {{orderDetail.marketCheckMsg?orderDetail.marketCheckMsg:'-'}}
            </div>
            <div class="exam_meta" v-show="checkProgress>2">
                <div class="exam_title">销售处意见</div>
            </div>
            <div class="exam_content" v-show="checkProgress>2">
                {{orderDetail.salesCheckMsg?orderDetail.salesCheckMsg:'-'}}
            </div>
            <div class="exam_meta" v-show="checkProgress>3">
                <div class="exam_title">财务处意见</div>
            </div>
            <div class="exam_content" v-show="checkProgress>3">
                {{orderDetail.financialCheckMsg?orderDetail.financialCheckMsg:'-'}}
            </div>
            <span class="exam_act" v-show="!(isExhibition==1)" @click="goExamine">填写备注</span>
        </div>
    </div>
</template>
<script>
export default {
    props: {
        orderDetail: {
            type: Object
        },
        checkProgress: {
            type: String|Number
        },
        isExhibition: {
            type: String
        }
    },
    data() {
        return {

        }
    },
    methods: {
        /* 下载附件 */
        downloadFile() {
        let url = `${this.imgUrl}/downloadFile/${this.orderDetail.checkFiles}`;
        uni.navigateTo({
            url: '/pages/audit/index?url='+url
        })
        },
        goExamine() {
            this.$router.push({
                name: 'audit',
                params: {
                    orderDetail: this.orderDetail,
                    checkProgress: this.checkProgress,
                    isExhibition: this.isExhibition
                }
            })
            // uni.navigateTo({
            //     url: '/pages/audit/index?orderDetail='+,
            //     fail: (e) => {
            //         console.log(e)
            //     }
            // })
        }
    }
}
</script>
<style lang="less" scoped>
.list_content{
    width: 100%;
    box-sizing: border-box;
    padding: 1.5rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: #fff;
    .list{
        font-size: 1.2rem;
        .list_title{
            color: #999;
        }
        span{
            display: inline-block;
            margin-top: .5rem;
        }
    }
}
.exam_list{
    width: 100%;
    box-sizing: border-box;
    padding: 0 1.5rem 1.5rem;
    background: #fff;
    .exam_meta{
        display: flex;
        align-items: center;
        justify-content: flex-start;
        .exam_title{
            font-size: 1.2rem;
            color: #999;
        }
        .exam_enclosure{
            flex: 1;
            display: flex;
            align-items: center;
            justify-content: flex-end;
            color: #0F8FD3;
            font-size: 1.2rem;
        }
    }
    .exam_content{
        font-size: 1.2rem;
        color: #353535;
        margin-top: .5rem;
        margin-bottom: .5rem;
    }
    .exam_act{
        display: inline-block;
        margin-top: .6rem;
        font-size: 1.2rem;
        color: #2A9BD8;
    }
}
</style>
