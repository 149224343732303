<template>
	<div class="map_content">
		<div class="title">原始发货照片</div>
		<div class="p_main">
			<div
				class="order_photo"
				v-for="(item, index) in factoryPhotos"
				:key="item.id"
				v-show="factoryPhotos.length>=1"
			>
				<img
					:src="item.pic?imgUrl+item.pic:noPic"
					:alt="item.pic?'':'没有图片'"
					srcset=""
					@click="imgPreview(factoryPhotos,index,)"
				>
			</div>
			<div class="order_photo" v-for="item in 4" :key="item" v-show="!factoryPhotos.length>=1">
				<img :src="noPic" alt="没有图片" srcset="">
			</div>
		</div>
    <div v-for="(item,i) in moreGroupsPhotos" :key="i">
      <div class="title">车辆到货照片<span v-if="moreGroupsPhotos.length > 1">(第{{i+1}}组)</span></div>
      <div class="p_main">
        <div
          class="order_photo"
          v-for="(photo, index) in item.arrivalPhotos"
          :key="photo.id"
          v-show="item.arrivalPhotos.length>=1"
        >
          <img
            :src="photo.pic?imgUrl+photo.pic:noPic"
            :alt="photo.pic?'':'没有图片'"
            srcset=""
            @click="imgPreview(item.arrivalPhotos,index)"
          >
        </div>
        <div class="order_photo" v-for="i of (4- item.arrivalPhotos.length)" :key="i" >
          <img :src="noPic" alt="没有图片" srcset="">
        </div>
      </div>
      <div class="title">空车照片<span v-if="moreGroupsPhotos.length > 1">(第{{i+1}}组)</span></div>
      <div class="p_main">
        <div
          class="order_photo"
          v-for="(photo, index) in item.emptyPhotos"
          :key="photo.id"
          v-show="item.emptyPhotos.length>=1"
        >
          <img
            :src="photo.pic?imgUrl+photo.pic:noPic"
            :alt="photo.pic?'':'没有图片'"
            srcset=""
            @click="imgPreview(item.emptyPhotos,index)"
          >
        </div>
        <div class="order_photo" v-for="i of (4- item.emptyPhotos.length)" :key="i" >
          <img :src="noPic" alt="没有图片" srcset="">
        </div>
      </div>
    </div>
	</div>
</template>

<script >
import { ImagePreview } from "vant";
import { imgUrl } from "@/utils/config.js";
export default {
  props: {
    orderDetailPhoto: {
      type: Array,
      default: []
    }
  },
  data() {
    return {
      noPic: require("@/assets/images/no_img.png")
    };
  },
  methods: {
	/* 图片预览 */   
    imgPreview(img, i) {
      if(!img[i]){
        return
      }
      let imgList = img.map(item => {
        return imgUrl + item.pic;
      });
      ImagePreview({
        images: imgList,
        startPosition: i,
        closeable: true
      });
    },
  },
  computed: {
    /* 多组照片 */
    moreGroupsPhotos() {
      const moreGroups = [...new Set(this.orderDetailPhoto.filter(item => item.picGroup !== null).map(item => item.picGroup))];
      const moreGroupsPhotos = [];
      moreGroups.forEach((item) => {
        moreGroupsPhotos.push({
          arrivalPhotos: this.orderDetailPhoto.filter(photo => photo.picGroup == item && photo.picType == 2).slice(0, 4).map((item) => {
            item.pic = item.pic && `/${item.pic.replace(/^\//, '')}`;
            return item;
          }),
          emptyPhotos: this.orderDetailPhoto.filter(photo => photo.picGroup == item && photo.picType == 3).slice(0, 4).map((item) => {
            item.pic = item.pic && `/${item.pic.replace(/^\//, '')}`;
            return item;
          }),
        });
      });
      if (moreGroupsPhotos.length > 0) {
        return moreGroupsPhotos;
      }
      moreGroupsPhotos.push({ arrivalPhotos: [], emptyPhotos: [] });
      return moreGroupsPhotos;
    },
    /* 重车图片 */
    arrivalPhotos() {
      return this.orderDetailPhoto.filter(item => item.picType === 2).slice(0, 4).map(item => {
        item.pic = item.pic && `/${item.pic.replace(/^\//, "")}`;
        return item;
      });
    },
    /* 空车图片 */
    emptyPhotos() {
      return this.orderDetailPhoto
        .filter(item => item.picType === 3)
        .slice(0, 4)
        .map(item => {
          item.pic = item.pic && `/${item.pic.replace(/^\//, "")}`;
          return item;
        });
    },
    /* 发货图片 */
    factoryPhotos() {
      return this.orderDetailPhoto
        .filter(item => item.picType === 1 || item.picType === 4)
        .slice(0, 4)
        .map(item => {
          item.pic = item.pic && `/${item.pic.replace(/^\//, "")}`;
          return item;
        });
    }
  }
};
</script>

<style lang="less" scoped>
.map_content {
  padding: 1.5rem;
  background-color: #ffffff;

  .title {
    font-size: 1.6rem;
    color: #333333;
  }

  .p_main {
    padding: 1.5rem 0;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    justify-content: space-between;
    .order_photo {
      width: 8.33rem;
      height: 6.25rem;
      margin-bottom: 5px;
      img {
        width: 100%;
        height: 100%;
      }
    }
  }
}
</style>
