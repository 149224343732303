<template>
	<div class="chart_content">
		<div class="title">载重曲线</div>
		<div class="charts_main">
			<div id="echartsData"></div>
		</div>
	</div>
</template>

<script>
import moment from "moment";
import { getEdgeGps, getBmapEdgeGps } from '@/utils/gpsUtil';

const echarts = require("echarts/lib/echarts");
// 引入柱状图组件
require("echarts/lib/chart/line");
// 引入提示框和title组件
require("echarts/lib/component/grid");
require("echarts/lib/component/legend");
require("echarts/lib/component/tooltip");
require("echarts/lib/component/markArea");
require("echarts/lib/component/dataZoom");
require("echarts/lib/component/markPoint");
require("echarts/lib/component/axisPointer");

export default {
  props: {
    orderDetail: {
      type: Object,
      default: () => {}
    },
    orderWarningData: {
      type: Array,
      default: []
    },
    orderDetailGps: {
      type: Array,
      default: []
    },
    orderDetailRail: {
      type: Array,
      default: []
    },
    isCement: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      myChart: null,
      originalWeight: null,
      isShowPackage: true,
      echartsOptions: {
        tooltip: {
          trigger: "axis",
          formatter: param => {
            const marker =
              '<span style="display:inline-block;margin-right:5px;border-radius:10px;width:10px;height:10px;background-color:#FFF;"></span>';
            const ad =
              this.echartsOptions.series[0].ADData[param[0].dataIndex] ||
              "- - - -";
            if (param.length == 3) {
              return `${param[0].axisValue}<br><table>
                <tr><td>${param[0].marker}</td><td>${
                param[0].seriesName
              }</td><td>${param[0].value}</td></tr>
                <tr><td>${param[1].marker}</td><td>${
                param[1].seriesName
              } </td><td>${param[1].value}</td></tr>
                <tr><td>${marker}         </td><td>AD                    </td><td>${ad}</td></tr>
                <tr><td>${param[2].marker}</td><td>${
                param[2].seriesName
              }</td><td>${param[2].value}</td></tr>
              </table>`;
            } else {
              return `${param[0].axisValue}<br><table>
                <tr><td>${param[0].marker}</td><td>${
                param[0].seriesName
              }</td><td>${param[0].value}</td></tr>
                <tr><td>${marker}         </td><td>AD                    </td><td>${ad}</td></tr>
                <tr><td>${param[1].marker}</td><td>${
                param[1].seriesName
              } </td><td>${param[1].value}</td></tr>
              </table>`;
            }
          }
        },
        grid: [
          {
			bottom: "42%",
			top: 20
          },
          {
			top: "68%",
          }
        ],
        axisPointer: {
          link: { xAxisIndex: "all" }
        },
        dataZoom: [
          {
            show: true,
            realtime: true,
            start: 0,
            end: 100,
            xAxisIndex: [0, 1]
          },
          {
            type: "inside",
            realtime: true,
            start: 0,
            end: 100,
            xAxisIndex: [0, 1]
          }
        ],
        xAxis: [
          {
            show: true,
            data: [],
            axisLabel: {
              show: false
            },
            boundaryGap: false
          },
          {
            data: [],
            gridIndex: 1,
            boundaryGap: false
          }
        ],
        yAxis: [
          {
            name: "吨位(t)",
            nameGap: 5,
            splitLine: { show: false },
            axisPointer: {
              snap: true
            }
          },
          {
            name: "速度(km/h)",
            nameGap: 6,
            splitLine: { show: false },
            gridIndex: 1,
            splitNumber: 2
          }
        ],
        series: [
          {
            name: "吨位",
            type: "line",
            symbol: "none", // 取消折点圆圈
            smooth: 1,
            data: [],
            markArea: {
              data: [],
              itemStyle: {
                color: "#8cdee9"
              }
            },
            markPoint: {
              symbol: "pin",
              symbolSize: 30,
              data: [],
              itemStyle: {
                normal: {
                  color: "#ff6200",
                  label: {
                    show: false,
                    position: "top",
                    formatter() {
                      return "";
                    }
                  }
                }
              }
            }
          },
          {
            name: "速度",
            type: "line",
            smooth: 1,
            symbol: "none", // 取消折点圆圈
            data: [],
            markArea: {
              data: [],
              itemStyle: {
                color: "#8cdee9"
              }
            },
            lineStyle: {
              normal: { color: "#003aff", opacity: "0.6" }
            },
            xAxisIndex: 1,
            yAxisIndex: 1
          },
          {
            name: "原始吨位",
            type: "line",
            symbol: "none",
            smooth: 1,
            data: [],
            markArea: {
              data: [],
              itemStyle: {
                color: "#000"
              }
            },
            lineStyle: {
              normal: { color: "#32ccb6", opacity: "0.6" }
            }
          }
        ]
      }
    };
  },
  methods: {
    /* 图表初始化 */
    initEcharts() {
      this.$nextTick(() => {
        if (!this.myChart) {
          this.myChart = echarts.init(document.getElementById("echartsData"));
          this.myChart.setOption(this.echartsOptions);
        }
      });
    },
    /* 轨迹数据图表异常初始化 */
    initGpsEchartErrsData() {
      if (this.orderDetail.packageType != '1') return;
      if (this.orderDetailGps.length < 1) return;
      this.$nextTick(() => {
        if (!this.myChart) {
          this.myChart = echarts.init(document.getElementById('echartsData'));
        }
        const orderWarningData = this.orderWarningData.filter(item => item.alarmType != '11' && item.alarmType != '12' && item.alarmType != '6' && item.alarmType != '7').sort((a, b) => new Date(a.receiveTime).getTime() - new Date(b.receiveTime).getTime());
        const orderLastWarningData = this.orderWarningData.filter(item => item.alarmType == '6' || item.alarmType == '7').sort((a, b) => new Date(a.receiveTime).getTime() - new Date(b.receiveTime).getTime());
        const GPSLENGTH = this.orderDetailGps.length;
        const orderDetailGpsTime = this.orderDetailGps.map(item => new Date(item.receiveTime).getTime());
        const nearlyTime = [];
        orderWarningData.forEach((item) => {
          const allTime = orderDetailGpsTime.map(items => items - new Date(item.receiveTime?item.receiveTime:item.receiveTime));
          const sortTime = [...allTime].sort((a, b) => Math.abs(a) - Math.abs(b) || b - a);
          const time = allTime.indexOf(sortTime[0]);
          nearlyTime.push(time);
        });
        const errDatas = orderWarningData.map((item, i) => ({ name: item, coord: [this.orderDetailGps[nearlyTime[i]].receiveTime, this.orderDetailGps[nearlyTime[i]].reWeight] }));
        const orderLastErrDatas = orderLastWarningData.map((item, i) => ({ name: item, coord: [this.echartsOptions.xAxis[0].data[this.echartsOptions.xAxis[0].data.length - 1], 0] }));
        if (orderLastErrDatas.length > 0) {
          errDatas.push(...orderLastErrDatas);
        }
        this.echartsOptions.series[0].markPoint.data = errDatas;
        this.myChart.setOption(this.echartsOptions);
        this.myChart.on('mouseover', (params) => {
          if (params.componentType === 'markPoint') {
            this.hideErr = true;
            if (params.name.alarmType == '2') {
              this.echartsErrmsg = timeTransform(params.name.timeoutDuration);
            } else {
              this.echartsErrmsg = params.name.alarmName;
            }
          } else {
            this.hideErr = false;
            this.echartsErrmsg = '';
          }
        });
      });
    },
    /* 轨迹数据图表初始化 */
    initGpsEchartsData() {
      if (this.orderDetail.packageType != "1") return;
      this.$nextTick(() => {
        if (!this.myChart) {
          this.myChart = echarts.init(document.getElementById("echartsData"));
        }
        const eachrtsDataTime = this.orderDetailGps.map(
          item => item.receiveTime
        );
        const eachrtsSpeedValue = this.orderDetailGps.map(item => item.speed);
        //载重空数据替换
        let replaceReweightData = this.orderDetail.fahuoshuliang
          ? this.orderDetail.fahuoshuliang
          : this.orderDetail.zl;
        const eachrtsWeightValue = this.orderDetailGps.map(item => {
          if (
            this.orderDetail.officeId == 101 &&
            moment(this.orderDetail.chuchangriqi).diff(
              moment("9999-05-07 02:00:00"),
              "seconds"
            ) > 0
          ) {
            return item.reWeight;
          } else {
            if (this.isCement) {
              if (item.reWeight == null) {
                item.reWeight = replaceReweightData;
              } else {
                replaceReweightData = item.reWeight;
              }
              /*订单发货数量*/
              const fahuoshuliang = this.orderDetail.fahuoshuliang;
              if (fahuoshuliang <= 0.25) {
                return item.reWeight;
              } else {
                if (item.reWeight <= fahuoshuliang) {
                  return item.reWeight;
                } else {
                  const number = (item.reWeight % 2) % 0.25;
                  let a = Math.round(item.reWeight / 1) % 2 == 1 ? -1 : 1;
                  return (fahuoshuliang + number.toFixed(2) * a).toFixed(2);
                }
              }
            } else {
              const fahuoshuliang = this.orderDetail.zl;
              if (fahuoshuliang <= 0.25) {
                return item.reWeight;
              } else {
                if (item.reWeight <= fahuoshuliang) {
                  return item.reWeight;
                } else {
                  const number = (item.reWeight % 2) % 0.25;
                  let a = Math.round(item.reWeight / 1) % 2 == 1 ? -1 : 1;
                  return (fahuoshuliang + number.toFixed(2) * a).toFixed(2);
                }
              }
            }
          }
        });
        //原始载重空数据替换
        let replaceWeightData = this.orderDetail.fahuoshuliang
          ? this.orderDetail.fahuoshuliang
          : this.orderDetail.zl;
        //保存原始载重数据
        const originalWeight = this.orderDetailGps.map(item => {
          if (item.weight == null) {
            item.weight = replaceWeightData;
          } else {
            replaceWeightData = item.weight;
          }
          return item.weight;
        });
        this.originalWeight = originalWeight;
        let repalceAdData = "";
        const eachrtsAdValue = this.orderDetailGps.map(item => {
          if (item.ad1 == null) {
            item.ad1 = repalceAdData;
          } else {
            repalceAdData = item.ad1;
          }
          return item.ad1;
        });

        if (
          new Date(eachrtsDataTime[0]).getTime() >=
          new Date(this.orderDetail.chuchangriqi).getTime()
        ) {
          eachrtsDataTime.unshift(this.orderDetail.chuchangriqi);
          eachrtsSpeedValue.unshift(0);
          eachrtsWeightValue.unshift(this.orderDetail.fahuoshuliang || 0);
          eachrtsAdValue.unshift("-");
        }
        if (
          this.noFlowing &&
          new Date(eachrtsDataTime[eachrtsDataTime.length - 1]).getTime() <=
            new Date(this.orderDetail.endTime).getTime()
        ) {
          eachrtsDataTime.push(this.orderDetail.endTime);
          eachrtsSpeedValue.push(0);
          eachrtsWeightValue.push(0);
          eachrtsAdValue.unshift("-");
        }
        if (
          this.noFlowing &&
          new Date(eachrtsDataTime[eachrtsDataTime.length - 1]).getTime() <=
            new Date(this.orderDetail.chumenriqi).getTime()
        ) {
          eachrtsDataTime.push(this.orderDetail.chumenriqi);
          eachrtsSpeedValue.push(0);
          eachrtsWeightValue.push(0);
          eachrtsAdValue.unshift("-");
        }
        this.originalWeight.unshift(eachrtsWeightValue[0]);
        this.echartsOptions.xAxis[0].data = eachrtsDataTime;
        this.echartsOptions.xAxis[1].data = eachrtsDataTime;
        this.echartsOptions.series[0].data = eachrtsWeightValue;
        this.echartsOptions.series[0].ADData = eachrtsAdValue;
        this.echartsOptions.series[1].data = eachrtsSpeedValue;
        // if (!this.onOff) {
        //   this.echartsOptions.series[2].data = this.originalWeight;
        // }
        this.myChart.setOption(this.echartsOptions);
      });
	},
	
    /* 图表围栏数据展示 */
    initGpsRailEchartsData() {
      if (this.orderDetail.packageType != "1") return;
      this.$nextTick(() => {
        console.log(1);
        if (!this.myChart) {
          this.myChart = echarts.init(document.getElementById("echartsData"));
        }
        const allRailNearlyGpsPoint = [];
        this.orderDetailRail.forEach((item, i) => {
          const point = getBmapEdgeGps(this.orderDetailGpsData, item.railData);
          allRailNearlyGpsPoint.push(...point);
        });
        allRailNearlyGpsPoint.forEach(item => {
          if (!item.end) {
            item.end = this.orderDetailGps[this.orderDetailGps.length - 1];
          }
        });
        const railDatasTime = [];
        allRailNearlyGpsPoint.forEach(item => {
          railDatasTime.push([
            { xAxis: item.start.receiveTime },
            { xAxis: item.end.receiveTime }
          ]);
        });
        this.echartsOptions.series[0].markArea.data = railDatasTime;
        this.echartsOptions.series[1].markArea.data = railDatasTime;
        this.myChart.setOption(this.echartsOptions);
      });
    },
    /* 没有异常点图表数据 */
    initNoErrsEchartsData() {
      if (this.orderDetail.packageType != '1') return;
      this.$nextTick(() => {
        if (!this.myChart) {
          this.myChart = echarts.init(document.getElementById('echartsData'));
        }
        this.echartsOptions.series[0].markPoint.data = [];
        this.myChart.setOption(this.echartsOptions);
      });
    },
  },
  watch: {
    orderWarningData(val) {
      if (val && val.length > 0) {
        this.initGpsEchartErrsData();
      } else {
        this.initNoErrsEchartsData();
      }
    },
    orderDetail(val) {
      if (val.packageType == "1") {
        this.isShowPackage = true;
        this.initEcharts();
      } else {
        this.isShowPackage = false;
        if (this.myChart) {
          this.myChart.dispose();
          this.myChart = null;
        }
      }
    },
    orderDetailGps(val) {
      if (val && val.length > 0) {
        this.initGpsEchartsData();
        if (this.orderDetailRail && this.orderDetailRail.length > 0) {
          this.initGpsRailEchartsData();
        }
      } else {
        // this.initNoEchartsData();
      }
    }
  },
  computed: {
	  /* 空定位数据过滤 */ 
    orderDetailGpsData: {
      get() {
        return this.orderDetailGps.filter( item => item.lat && item.lon)
      },
      set() {

      }
    }
  },
};
</script>

<style lang="less" scoped>
.chart_content {
  width: 100%;
  height: 27.8rem;
  padding: 1.5rem;
  background-color: #ffffff;
  box-sizing: border-box;

  .title {
    font-size: 1.6rem;
    color: #333333;
  }

  .charts_main {
    margin-top: 1.5rem;

    #echartsData {
      display: block;
      width: 100%;
      height: 21.1rem;
    }
  }
}
</style>
