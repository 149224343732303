<template>
	<div class="map_content">
		<div class="title">运输轨迹</div>
		<div class="map_main">
			<div id="maps"></div>
		</div>
	</div>
</template>

<script>
import transformPoints from '@/utils/gpsTransform.js';
export default {
  props: {
    orderDetailGps: {
      type: Array
    },
    orderDetailRail: {
      type: Array
    },
    orderWarningData: {
      type: Array,
      default: []
    }
  },
  data() {
    return {
      polylines: [
        {
          points: [],
          color: "#0F8FD3",
          width: 2
        }
      ],
      markers: [],
	  polygons: [],
	  railData: [],
      map: null
    };
  },
  methods: {
    /* 地图初始化 */
    initMapData() {
      this.$nextTick(() => {
        this.map = new BMap.Map("maps"); // 创建Map实例
		this.map.addControl(new BMap.MapTypeControl());
        this.map.addControl(new BMap.NavigationControl());
        this.map.centerAndZoom(new BMap.Point(116.399, 39.91), 14);
      });
    },
    /* 轨迹数据地图初始化 */
    initGpsMapData() {
      const allPoints = this.orderDetailGpsData.map(item => {
        const gpsPoint = transformPoints(item.lat, item.lon);
        return new BMap.Point(gpsPoint.lng, gpsPoint.lat);
      });
      const view = this.map.getViewport(eval(allPoints));
      this.map.centerAndZoom(view.center, view.zoom);
      const startGpsPoint = transformPoints(
        this.orderDetailGpsData[0].lat,
        this.orderDetailGpsData[0].lon
      );
      const endGpsPoint = transformPoints(
        this.orderDetailGpsData[this.orderDetailGpsData.length - 1].lat,
        this.orderDetailGpsData[this.orderDetailGpsData.length - 1].lon
      );
      const startPoint = new BMap.Point(startGpsPoint.lng, startGpsPoint.lat);
      const endPoint = new BMap.Point(endGpsPoint.lng, endGpsPoint.lat);
      const startIcon = new BMap.Icon(
        require("@/assets/images/icons.png"),
        new BMap.Size(28, 28),
        { imageOffset: new BMap.Size(-10, -58) }
      );
      const factoryIcon = new BMap.Icon(
        require("@/assets/images/mapIcon/gongchang.png"),
        new BMap.Size(28, 28),
        { imageSize: new BMap.Size(28, 28) }
      );
      let endIcon;
      if (this.noFlowing) {
        endIcon = new BMap.Icon(
          require("@/assets/images/icons.png"),
          new BMap.Size(28, 28),
          { imageOffset: new BMap.Size(-10, -200) }
        );
      } else {
        endIcon = new BMap.Icon(
          require("@/assets/images/mapIcon/track.png"),
          new BMap.Size(28, 28),
          { imageSize: new BMap.Size(28, 28) }
        );
      }
      const startMarker = new BMap.Marker(startPoint, { icon: startIcon });
      const factoryMarker = new BMap.Marker(startPoint, {
        icon: factoryIcon,
        offset: new BMap.Size(-10, -10)
      });
      const endMarker = new BMap.Marker(endPoint, { icon: endIcon });
      const strokeLine = new BMap.Polyline(allPoints, {
        strokeColor: "blue",
        strokeWeight: 2
      });
      factoryMarker.type = "gps";
      startMarker.type = "gps";
      endMarker.type = "gps";
      strokeLine.type = "gps";
      // this.allBmapPoints = allPoints;
      this.map.addOverlay(factoryMarker);
      this.map.addOverlay(startMarker);
      this.map.addOverlay(endMarker);
      this.map.addOverlay(strokeLine);
	},
	/* 轨迹数据围栏初始化 */
    initGpsMapRailData() {
      this.orderDetailRail.forEach((item, i) => {
        const railData = item.railData
          .split(';')
          .filter(item => item)
          .map(items => items.split(','));
        const points = railData.map(item => new BMap.Point(item[1], item[0]));
        const stokeStyle = item.railType == 2 ? { stokeColor: 'rgb(0, 153, 153)', strokeWeight: 1, fillColor: 'rgb(0, 153, 153)' } : { stokeColor: 'blue', strokeWeight: 1, fillColor: 'red' };
        const polygon = new BMap.Polygon(points, stokeStyle);
        polygon.type = 'rail';
        // item.railType == 2 ? this.areaRail = new BMap.Polygon(points) : this.dealerRail = new BMap.Polygon(points);
        this.map.addOverlay(polygon);
        this.railData.push(polygon);
      });
    },
	/* 根据类型清除当前地图 */
    clearCurrentMap(type) {
      if (this.map) {
        const overLayers = this.map.getOverlays();
        if (overLayers && overLayers.length > 0) {
          for (const item of overLayers) {
            if (item.type == type) {
              this.map.removeOverlay(item);
            }
          }
        }
      }
	},
	/* 没有轨迹的的异常数据地图 */
    initNoGpsData() {
      this.$nextTick(() => {
        const carIcon = new BMap.Icon(require('@/assets/images/mapIcon/track.png'), new BMap.Size(28, 28), { imageSize: new BMap.Size(28, 28) });
        const errPoint = new BMap.Point(null, null);
        const errMarker = new BMap.Marker(errPoint, { icon: carIcon });
        const labelend = new BMap.Label('暂时没有GPS数据', { offset: new BMap.Size(20, -10) });
        this.map.centerAndZoom(errPoint, 7);
        errMarker.setLabel(labelend);
        errMarker.type = 'errMarker';
        this.map.addOverlay(errMarker);
      });
    },
    /* 轨迹数据异常地图初始化 */
    initGpsMapErrData() {
      if(this.orderDetailGpsData.length == 0) {
        return
      }
      const errIcon = new BMap.Icon(require('@/assets/images/mapIcon/stopFlag.png'), new BMap.Size(28, 28), { imageSize: new BMap.Size(28, 28) });
      const carIcon = new BMap.Icon(require('@/assets/images/mapIcon/track.png'), new BMap.Size(28, 28), { imageSize: new BMap.Size(28, 28) });
      const orderWarningData = this.orderWarningData.filter(item => item.lat && item.alarmType != 11 && item.alarmType != 12);
      if (orderWarningData.length > 0) {
        orderWarningData.forEach((item) => {
          const errGpsPoint = transformPoints(item.lat, item.lon);
          const errPoint = new BMap.Point(errGpsPoint.lng, errGpsPoint.lat);
          let errMarker;
          if (item.alarmName == '无轨迹数据') {
            errMarker = new BMap.Marker(errPoint, { icon: carIcon, title: `${item.receiveTime?item.receiveTime:item.createDate}\n${item.alarmName}` });
            const labelend = new BMap.Label('暂时没有GPS数据', { offset: new BMap.Size(20, -10) });
            errMarker.setLabel(labelend);
          } else if (item.alarmType == '2') {
            errMarker = new BMap.Marker(errPoint, { icon: errIcon, title: `${item.receiveTime?item.receiveTime:item.createDate}\n${timeTransform(item.timeoutDuration)}`, offset: new BMap.Size(20, -10) });
          } else {
            errMarker = new BMap.Marker(errPoint, { icon: errIcon, title: `${item.receiveTime?item.receiveTime:item.createDate}\n${item.alarmName}`, offset: new BMap.Size(20, -10) });
          }
          errMarker.type = 'errMarker';
          this.map.addOverlay(errMarker);
        });
      }
    },
  },
  computed: {
	  /* 空定位数据过滤 */ 
    orderDetailGpsData: {
      get() {
        return this.orderDetailGps.filter( item => item.lat || item.lon)
      },
      set() {

      }
    }
  },
  watch: {
    orderWarningData(val){
      this.clearCurrentMap('errMarker');
      if (val && val.length > 0) {
        this.initGpsMapErrData();
      } else {
      }
    },
	   /* gps数据 */
    orderDetailGps(val) {
      this.clearCurrentMap('gps');
      if (val && val.length > 0) {
        this.initGpsMapData();
      } else {
        this.initNoGpsData();
      }
	},
	/* 围栏数据 */ 
	orderDetailRail(val) {
		this.clearCurrentMap('rail');
		if(val && val.length>0) {
      this.initGpsMapRailData();
		}
		if (this.orderDetailGps.length > 0) {
        this.initGpsMapData();
      }
	}
  },
  mounted() {
    this.initMapData();
  }
};
</script>

<style lang="less" scoped>
.map_content {
  padding: 1.5rem;
  background-color: #ffffff;

  .title {
    font-size: 1.6rem;
    color: #333333;
  }

  .map_main {
    padding-top: 1.5rem;
    #maps {
      width: 100%;
      height: 25.8rem;
    }
  }
}
</style>
