<template>
  <div class="page_content">
    <div class="exam_content">
      <order-examine :isExhibition="isExhibition" :orderDetail="orderDetail" :checkProgress="checkProgress"/>
    </div>
    <div class="msg_content">
      <order-msg :orderInfo="orderDetail"></order-msg>
    </div>
    <div class="msg_content">
      <order-map :orderDetailGps="orderDetailGps" :orderDetailRail="orderDetailRail" :orderWarningData="orderWarningData"></order-map>
    </div>
    <div class="chart_content">
      <order-charts
        :orderDetail="orderDetail"
        :orderDetailGps="orderDetailGps"
        :orderDetailRail="orderDetailRail"
        :orderWarningData="orderWarningData"
      ></order-charts>
    </div>
    <div class="photo_content">
      <order-photo :orderDetailPhoto="orderDetailPhoto"></order-photo>
    </div>
    <div class="order_bottom" v-show="!(isExhibition==1)">
      <div class="bottom_btn" style="background: #D9011B" @click="sureExamine(2)">不通过</div>
      <div class="bottom_btn" style="background: #F59A23" @click="goback">待审核</div>
      <div class="bottom_btn" style="background: #12BC75" @click="sureExamine(1)">通过</div>
    </div>
  </div>
</template>

<script>
import OrderExamine from "./components/OrderExamine.vue";
import OrderCharts from "./components/OrderCharts.vue";
import OrderMsg from "./components/OrderMsg.vue";
import OrderMap from "./components/OrderMap.vue";
import OrderPhoto from "./components/OrderPhoto.vue";
import { setCookie } from "@/utils/tool.js";
import { Dialog, Notify } from "vant";
import moment from "moment";
export default {
  components: {
    OrderExamine,
    OrderCharts,
    OrderMsg,
    OrderMap,
    OrderPhoto
  },
  data() {
    return {
      row: null,
      orderDetail: {},
      orderDetailRail: null,
      orderDetailGps: [],
      orderDetailPhoto: [],
      row: {},
      checkProgress: null,
      orderWarningData: [],
      isExhibition: null
    };
  },
  mounted() {
    console.log("router", this.$router);
    let token = this.$route.query.token;
    setCookie("remember_user_token", token);
    this.row = JSON.parse(decodeURI(this.$route.query.row));
    this.checkProgress = Number(this.$route.query.checkProgress);
    this.isExhibition = this.$route.query.isExhibition;
    if(this.isExhibition == 1) {
      this.checkProgress = 4
    }
    this.getOrderDetail();
  },
  methods: {
    //
    goback() {
      uni.navigateBack({
        delta: 1
      });
    },
    /* 审核订单 */
    sureExamine(checkStatus) {
      Dialog.confirm({
        title: "提示",
        message: "您确定要将审核结果提交吗?"
      })
        .then(async option => {
          try {
            await this.$http.examineOrder({
              checkStatus,
              checkLevel: this.checkProgress,
              orderId: this.orderDetail.id
            });
            Notify({
              type: "success",
              message: "订单审核成功。"
            });
            setInterval(() => {
              uni.switchTab({
                url: "/pages/examine/index"
              });
            }, 1000);
          } catch (e) {
            console.log(e);
          }
        })
        .catch(() => {
          console.log("取消");
        });
    },
    // 获取订单详情数据
    async getOrderDetail() {
      try {
        const gpsData = [];
        let current = 1;
        let that = this;
        (async function next() {
          if (!that.row.carId) {
            return;
          }
          let end = that.row.endTime
            ? that.row.endTime
            : moment().format("YYYY-MM-DD HH:mm:ss");
          const {
            data: { data: orderDetailGps }
          } = await that.$http.getOrderGps(
            that.row.carId,
            `startTime=${
              that.row.chuchangriqi
            }&endTime=${end}&current=${current}`
          );
          if (!orderDetailGps) {
            that.orderDetailGps = [];
            return;
          }
          console.log("第" + current + "次", orderDetailGps);
          gpsData.push(...orderDetailGps);
          gpsData.map(item => {
            item.receiveTime = item.receiveTime.replace(/-/g,"/");
            item.createTime = item.createTime.replace(/-/g,"/");
            return item
          })
          console.log("分段请求结果", gpsData.length);
          that.orderDetailGps = gpsData;
          if (orderDetailGps.length == 3000) {
            current++;
            next(that.row, current);
          } else {
            try {
              const awaitOrderDetail = that.$http.getOrderDetail({
                orderId: that.row.id
              });
              const {
                data: { bizContent: orderDetail }
              } = await awaitOrderDetail;
              orderDetail.chuchangriqi = orderDetail.chuchangriqi.replace(/-/g,"/");
              orderDetail.endTime = orderDetail.endTime.replace(/-/g,"/");
              that.orderDetail = orderDetail;
              if (orderDetail.orderRail && orderDetail.orderRail.length > 0) {
                const orderRail = orderDetail.orderRail.substring(
                  1,
                  orderDetail.orderRail.length - 1
                );
                const ids = orderRail
                  .split(",")
                  .filter(item => item)
                  .join(",");
                const awaitOrderDetailRailGps = that.$http.getRailByIds({
                  ids
                });
                const {
                  data: { bizContent: orderDetailRail }
                } = await awaitOrderDetailRailGps;
                orderDetailRail.forEach(item => {
                  item.railData = item.railData.substring(
                    1,
                    item.railData.length - 1
                  );
                });
                that.orderDetailRail = orderDetailRail;
              } else {
                that.orderDetailRail = [];
              }
            } catch (e) {
              that.orderDetail = [];
              console.log(e);
            }
            if (
              new Date(
                that.orderDetailGps[that.orderDetailGps.length - 1].receiveTime
              ).getTime() <= new Date(that.orderDetail.endTime).getTime()
            ) {
              let a = {};
              const aa = that.orderDetailGps[that.orderDetailGps.length - 1];
              for (let key in aa) {
                a[key] = aa[key];
              }
              a.receiveTime = that.orderDetail.endTime;
              a.speed = 0;
              a.reWeight = 0;
              that.orderDetailGps.push(a);
            }
          }
        })(that.row, current);
      } catch (e) {
        this.orderDetailGps = [];
        console.log(e);
      }
      try {
        const awaitOrderWarning = this.$http.warningOrderDetail({
          orderId: this.row.id
        });
        const {
          data: { bizContent: warningTabelData }
        } = await awaitOrderWarning;
        this.orderWarningData = warningTabelData;
      } catch (e) {
        this.orderWarningData = [];
        console.log(e);
      }
      try {
        const awaitOrderDetailPhoto = this.$http.getOrderDetailPhoto({
          orderId: this.row.id
        });
        const {
          data: { bizContent: orderDetailPhoto }
        } = await awaitOrderDetailPhoto;
        this.orderDetailPhoto = orderDetailPhoto;
      } catch (e) {
        this.orderDetailPhoto = [];
        console.log(e);
      }
    }
  }
};
</script>

<style lang="less" scoped>
.page_content {
  background-color: #f1f1f1;
  min-height: 100vh;
  padding-bottom: 4.8rem;

  .msg_content {
    padding-top: 1.5rem;
  }

  .chart_content {
    padding-top: 1.5rem;
  }

  .photo_content {
    padding: 1.5rem 0;
  }

  .order_bottom {
    position: fixed;
    bottom: 0;
    left: 0;
    height: 4.8rem;
    width: 100%;
    box-sizing: border-box;
    padding: 0 1.5rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: #fff;
    .bottom_btn {
      cursor: pointer;
      width: 10rem;
      height: 3.5rem;
      text-align: center;
      line-height: 3.5rem;
      font-size: 1.6rem;
      color: #fff;
      border-radius: 2rem;
    }
  }
}
</style>
