/**
 * by lcs
 * 2019-07-22
 */
import gpsTransform from './gpsTransform';

const _isInPolygon = function (point, pts) {
  const N = pts.length;
  const boundOrVertex = true; // 如果点位于多边形的顶点或边上，也算做点在多边形内，直接返回true
  let intersectCount = 0; // cross points count of x
  const precision = 2e-10; // 浮点类型计算时候与0比较时候的容差
  let p1; let
    p2; // neighbour bound vertices
  const p = point; // 当前点

  p1 = pts[0]; // left vertex
  for (let i = 1; i <= N; ++i) { // check all rays
    if (p == p1) {
      return boundOrVertex; // p is an vertex
    }

    p2 = pts[i % N]; // right vertex
    if (p.x < Math.min(p1.x, p2.x) || p.x > Math.max(p1.x, p2.x)) { // ray is outside of our interests
      p1 = p2;
      continue; // next ray left point
    }

    if (p.x > Math.min(p1.x, p2.x) && p.x < Math.max(p1.x, p2.x)) { // ray is crossing over by the algorithm (common part of)
      if (p.y <= Math.max(p1.y, p2.y)) { // x is before of ray
        if (p1.x == p2.x && p.y >= Math.min(p1.y, p2.y)) { // overlies on a horizontal ray
          return boundOrVertex;
        }

        if (p1.y == p2.y) { // ray is vertical
          if (p1.y == p.y) { // overlies on a vertical ray
            return boundOrVertex;
          } // before ray
          ++intersectCount;
        } else { // cross point on the left side
          const xinters = (p.x - p1.x) * (p2.y - p1.y) / (p2.x - p1.x) + p1.y; // cross point of y
          if (Math.abs(p.y - xinters) < precision) { // overlies on a ray
            return boundOrVertex;
          }

          if (p.y < xinters) { // before ray
            ++intersectCount;
          }
        }
      }
    } else { // special case when ray is crossing through the vertex
      if (p.x == p2.x && p.y <= p2.y) { // p crossing over p2
        const p3 = pts[(i + 1) % N]; // next vertex
        if (p.x >= Math.min(p1.x, p3.x) && p.x <= Math.max(p1.x, p3.x)) { // p.x lies between p1.x & p3.x
          ++intersectCount;
        } else {
          intersectCount += 2;
        }
      }
    }
    p1 = p2; // next ray left point
  }

  if (intersectCount % 2 == 0) { // 偶数在多边形外
    return false;
  } // 奇数在多边形内
  return true;
};
/**
 * 获取围栏边缘 gps
 * @param {} orderGps
 * @param {*} rail
 */
const getEdgeGps = function (orderGps, rail) {
  const point = [];
  let last = false;
  rail = rail.split(';').map(a => a.split(',')).map(a => ({ x: a[0] * 1, y: a[1] * 1 }));
  orderGps
    .map((a) => {
      const gpsPoint = gpsTransform(a.lat, a.lon);
      return ({ ...a, x: gpsPoint.lat, y: gpsPoint.lng });
    })
    .forEach((gps) => {
      if (_isInPolygon(gps, rail)) {
        !last && point.push({ start: gps });
        last = true;
      } else {
        last && (point[point.length - 1].end = gps);
        last = false;
      }
    });
  return point;
};

const _isInBmapPolygon = function (point, polygon) {
  return BMapLib.GeoUtils.isPointInPolygon(point, polygon);
};

/**
 * 获取围栏边缘 gps
 * @param {} orderGps
 * @param {*} rail
 */
const getBmapEdgeGps = function (orderGps, rail) {
  const point = [];
  const railData = rail
    .split(';')
    .filter(item => item)
    .map(items => items.split(','));
  const points = railData.map(item => new BMap.Point(item[1], item[0]));
  const polygon = new BMap.Polygon(points);
  polygon.type = 'rail';
  let last = false;
  orderGps
    .map((a) => {
      const gpsPoint = gpsTransform(a.lat, a.lon);
      return ({ ...a, x: gpsPoint.lat, y: gpsPoint.lng });
    })
    .forEach((gps) => {
      const bmapPoint = new BMap.Point(gps.y, gps.x);
      if (_isInBmapPolygon(bmapPoint, polygon)) {
        !last && point.push({ start: gps });
        last = true;
      } else {
        last && (point[point.length - 1].end = gps);
        last = false;
      }
    });
  return point;
};

/**
 * 判断车辆静止
 * @param {*} gps
 */
let _isStop = gps => {
  if (!gps.speed) return true;
  return gps.speed <= 0;
};

/**
 * 获取停止的gps
 * @param {} orderGps
 * @param {*} rail
 */
const getStopGps = function (gpsList) {
  const point = [];
  let last = false;
  gpsList.forEach((gps) => {
      if (_isStop(gps)) {
        !last && point.push({ start: gps });
        last = true;
      } else {
        last && (point[point.length - 1].end = gps);
        last = false;
      }
    });
  return point;
};

export { getEdgeGps, getStopGps, getBmapEdgeGps };
